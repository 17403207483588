/* eslint-disable */
import { formatInTimeZone } from 'date-fns-tz';
import { format, parseISO } from 'date-fns';
import millify from 'millify';
import commaNumber from 'comma-number';

export const buttonize = (handlerFn) => ({
  role: 'button',
  onClick: handlerFn,
  tabIndex: 0,
  onKeyDown: (event) => {
    // insert your preferred method for detecting the keypress
    if (event.keycode === 13) handlerFn(event);
  },
});

export const millifyOptions = ({
  precision: 2,
  units: ['', 'K', 'M', 'B', 'T'],
});

export const millifyOptionsThree = ({
  precision: 3,
  units: ['', 'K', 'M', 'B', 'T'],
});

export const assetPrefix = '/trading-platform/assets';

export const loadingStates = {
  NO_ACTIVE_REQUEST: 'NO_ACTIVE_REQUEST',
  LOADING: 'LOADING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
};
export const bodyScrollLock = () => {
  if (document && document.body) document.body.style.overflowY = 'hidden';
  return undefined;
};

export const bodyScrollUnLock = () => {
  if (document && document.body) document.body.style.overflowY = 'scroll';
  return undefined;
};

export const renderNumber = (value, isCurrency = false, currencySymbol = '$') => {
  if (value !== 0 && !value) {
    return ('-');
  }
  let num = Number(value);
  if (Number.isNaN(num)) {
    return '-';
  }

  num = Number.parseFloat(Number(num).toFixed(2));

  let tempOptions = Math.abs(num) > 99999 ? millifyOptionsThree : millifyOptions;

  if (Math.abs(num) > 99999) {
    return !isCurrency ? millify(num, tempOptions) : `${currencySymbol}${millify(num, tempOptions)}`;
  }
  if (Number.isInteger(Math.abs(num))) {
    return !isCurrency ? commaNumber(num) : `${currencySymbol}${commaNumber(num)}`;
  }
  return !isCurrency ? commaNumber(num) : `${currencySymbol}${commaNumber(num)}`;
};

export const renderCurrency = (value) => renderNumber(value, true);

export const displayDateInUtc = (date) => {
  if (!date) {
    return '-';
  }
  if (typeof date.getTime === 'function') {
    return formatInTimeZone(date, 'UTC', 'yyyy-MM-dd HH:mm:ss zz');
  }
  if (typeof date === 'string') {
    try {
      return formatInTimeZone(parseISO(date), 'UTC', 'yyyy-MM-dd HH:mm:ss zz');
    } catch (e) {
      return '-';
    }
  }
  return '-';
};

export const dateRendererForChart = (date) => {
  if (!date) {
    return '-';
  }
  if (typeof date.getTime === 'function') {
    return format(date, 'dd LLL, yy');
  }
  if (typeof date === 'string') {
    try {
      return format(new Date(date), 'dd LLL, yy');
    } catch (e) {
      return '-';
    }
  }
  return '-';
};

// following function might be buggy as use case cannot be reproduced on frontend
export const getPreciseQuantityUSD = (unitPriceIn, initialQuantityIn) => {
  const maximumUsdToLose = 10;
  const maximumPercentageToLose = 10;
  const unitPrice = Number(unitPriceIn);
  const initialQuantity = Number(initialQuantityIn);
  if (Number.isNaN(unitPrice) || Number.isNaN(initialQuantity)) {
    return '-';
  }
  if (initialQuantity === 0) {
    return 0;
  }
  if (unitPrice === 0) {
    return 0;
  }
  const tenPercentOfPrice = (unitPrice * maximumPercentageToLose) / 100;
  // lose maximum of ten dollars
  const maxAmountToLose = Math.min(tenPercentOfPrice, maximumUsdToLose);
  const quantityForMinLose = Number((maxAmountToLose / unitPrice).toPrecision(1));
  const numOfDecimals = quantityForMinLose.countDecimals();
  const amountAfterLoss = Number(initialQuantity - quantityForMinLose).toFixed(numOfDecimals);
  const lengthOfQuantity = `${initialQuantity}`.length;
  const lengthOfPreciseDec = `${amountAfterLoss}`.length;
  if (lengthOfQuantity === lengthOfPreciseDec) {
    return initialQuantity;
  }
  if (lengthOfPreciseDec > lengthOfQuantity) {
    return initialQuantity;
  }

  return amountAfterLoss;
};
export const truncateDecimal = (number, decimalNum, isCurrency = false, currencySymbol = '$') => {
  const strNumber = number.toString();
  const [integerPart, decimalPart] = strNumber.split('.');
  let num = Number(number);
  if (Number.isNaN(num)) {
    return '-';
  }
  if (integerPart.length > 5) {
    return `${!isCurrency ? commaNumber(integerPart.slice(0, 5)) : `${currencySymbol}${commaNumber(integerPart.slice(0, 5))}`}...`;
  } else if (decimalPart && decimalPart.length > 2) {
    return `${!isCurrency ? `${commaNumber(integerPart)}.${decimalPart.slice(0, decimalNum)}` : `${currencySymbol}${commaNumber(integerPart)}.${decimalPart.slice(0, decimalNum)}`}...`;
  } else {
    return !isCurrency ? commaNumber(strNumber) : `${currencySymbol}${commaNumber(strNumber)}`;
  }
}

 export const getMonthInfo = (dateString) => {
  const dateObj = new Date(dateString);

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const year = dateObj.getFullYear();

  let daySuffix;
  switch (day) {
    case 1:
    case 21:
    case 31:
      daySuffix = "st";
      break;
    case 2:
    case 22:
      daySuffix = "nd";
      break;
    case 3:
    case 23:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
  }
  // const formattedDate = `${day}${daySuffix} ${month}, ${year}`;
  return { day, daySuffix, month, year };
}

export const formatDate = (inputDate, useFullMonthName = false) => {
  const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const fullMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [day, month, year] = inputDate.split('-');

  const months = useFullMonthName ? fullMonths : shortMonths;
  const monthDetails = useFullMonthName ? `${months[parseInt(month, 10) - 1]}` : `${months[parseInt(month, 10) - 1]} ${year.slice(-2)}`

  return monthDetails;;
};

export const getShortMonthRange = (startDate, endDate) => {
  const startMonth = format(new Date(startDate), 'MMM');
  const endMonth = format(new Date(endDate), 'MMM');
  return `${startMonth}-${endMonth}`;
};