/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { headersProvider } from './apiHelpers';
import constants from '../constants';

export const companyDataApi = () => axios.get(`${constants.tradersApiHost}/company/cover-data`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyPositionsApi = (status) => axios.get(`${constants.tradersApiHost}/company/company-data/positions?status=${status}`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyOrdersApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/orders`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyStakingApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/staking`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyChartSnapshotApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/chart-data-snapshot`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyChartDataApi = ({ chartType, startDate, endDate }) => axios.get(`${constants.tradersApiHost}/company/company-data/chart-data/${chartType}`, {
  params: {
    startDate,
    endDate,
  },
  headers: {
    ...headersProvider(),
  },
});
export const companyTransfersApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/transfers`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyMarketsApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/markets`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyOverviewApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/overview`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyRevenueApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/revenue`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyBonusApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/bonus`, {
  headers: {
    ...headersProvider(),
  },
});
export const balanceSheetDistributionApi = () => axios.get(`${constants.tradersApiHost}/company/balance-sheet/distribution`, {
  headers: {
    ...headersProvider(),
  },
});
export const balanceSheetFinancialApi = () => axios.get(`${constants.tradersApiHost}/company/balance-sheet/financials`, {
  headers: {
    ...headersProvider(),
  },
});
export const balanceSheetCurrencyApi = () => axios.get(`${constants.tradersApiHost}/company/balance-sheet/currency`, {
  headers: {
    ...headersProvider(),
  },
});
export const balanceSheetExchangeApi = () => axios.get(`${constants.tradersApiHost}/company/balance-sheet/exchange`, {
  headers: {
    ...headersProvider(),
  },
});
export const companyAssetsApi = ({ startDate, endDate }) => axios.get(`${constants.tradersApiHost}/company/company-data/assets`, {
  params: {
    startDate,
    endDate,
  },
  headers: {
    ...headersProvider(),
  },
});

export const companyWalletApi = () => axios.get(`${constants.tradersApiHost}/company/company-data/wallet`, {
  headers: {
    ...headersProvider(),
  },
});
export const V2companyBookAnalyticsApi = () => axios.get(`${constants.tradersApiHost}/company/v2-covers/company-book/analytics`, {
  headers: {
    ...headersProvider(),
  },
});
export const V2companyBookChartsApi = () => axios.get(`${constants.tradersApiHost}/company/v2-covers/company-book/charts`, {
  headers: {
    ...headersProvider(),
  },
});
export const V2balanceSheetAnalyticsApi = () => axios.get(`${constants.tradersApiHost}/company/v2-covers/balance-sheet/analytics`, {
  headers: {
    ...headersProvider(),
  },
});

export const V2bonusPoolChartsApi = () => axios.get(`${constants.tradersApiHost}/company/v2-covers/bonus-pool/charts`, {
  headers: {
    ...headersProvider(),
  },
});
export const V2bonusPoolAnalyticsApi = () => axios.get(`${constants.tradersApiHost}/company/v2-covers/bonus-pool/analytics`, {
  headers: {
    ...headersProvider(),
  },
});

export const companyPerformanceApi = () => axios.get(`${constants.tradersApiHost}/company/performance`, {
  headers: {
    ...headersProvider(),
  },
});
